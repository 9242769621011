
















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { Application } from "@/shared/models";

@Component
export default class DefaultApplicationPicker extends Vue {
  @Prop() value!: string | null;
  @Prop({ default: () => [] }) favoriteApplications!: Array<string>;

  get applications(): Array<Application> {
    return this.$store.state.application.apps.reduce(
      (result: Array<Application>, { name, id }: Application) => {
        if (!this.favoriteApplications.includes(id)) {
          return result;
        }

        return [
          ...result,
          {
            text: name || id,
            value: id,
          },
        ];
      },
      []
    );
  }

  @Watch("favoriteApplications", { deep: true })
  watchFavoriteApplications(newValue: Array<string>, oldValue: Array<string>) {
    if (!oldValue.length && newValue.length === 1) {
      this.localValue = newValue[0];
    }
  }

  get localValue(): string | null {
    return this.value;
  }

  set localValue(value: string | null) {
    this.$emit("input", value);
  }
}
