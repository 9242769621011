var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4",attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center"},[_c('v-col',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$lang("accountSettings.title"))+" ")]),_c('v-spacer'),(_vm.hasUnsavedChanges)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-2",attrs:{"fab":"","x-small":""},on:{"click":_vm.resetToPrevState}},on),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,false,3205357718)},[_vm._v(" "+_vm._s(_vm.$lang("btn.cancel"))+" ")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2 elevation-2",attrs:{"color":"primary","disabled":!_vm.valid,"loading":_vm.isSavingInProgress,"fab":"","x-small":""},on:{"click":_vm.handleSave}},on),[_c('v-icon',[_vm._v("mdi-check")])],1)]}}],null,false,939228089)},[_vm._v(" "+_vm._s(_vm.$lang("btn.save"))+" ")])],1):_vm._e()],1),_c('div',{staticClass:"d-flex mt-2"},[_c('div',[_c('v-card',{attrs:{"flat":""}},[_c('v-list',{attrs:{"rounded":"","dense":""}},[_c('v-list-item-group',{attrs:{"color":"primary","mandatory":""},model:{value:(_vm.selectedListItem),callback:function ($$v) {_vm.selectedListItem=$$v},expression:"selectedListItem"}},_vm._l((_vm.tabItems),function(ref,index){
var icon = ref.icon;
var text = ref.text;
return _c('v-list-item',{key:index},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)],1)}),1)],1)],1)],1),_c('div',{staticClass:"flex-grow-1 pl-2"},[_c('v-responsive',{staticClass:"overflow-y-auto",staticStyle:{"flex":"auto"},attrs:{"height":"calc(100vh - 136px)"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.selectedListItem === 0)?_vm._l((_vm.interfaceItems),function(item,index){return _c('div',{key:item.value},[(
                    index &&
                    item.value !==
                      _vm.AccountSettingType.SPECIFIC_FILTERS_BEHAVIOR
                  )?_c('v-divider',{staticClass:"mx-3"}):_vm._e(),_c('v-card-title',{staticClass:"text-h6 font-weight-medium pa-3",class:{ 'pb-0': !item.text },attrs:{"id":item.value ===
                    _vm.AccountSettingType.SPECIFIC_FILTERS_BEHAVIOR
                      ? 'specificFiltersBehavior'
                      : undefined}},[_vm._v(" "+_vm._s(item.title)+" ")]),(item.text)?_c('v-card-subtitle',{staticClass:"py-0 px-3"},[_vm._v(" "+_vm._s(item.text)+" ")]):_vm._e(),_c('v-card-text',{staticClass:"py-3"},[(item.value === _vm.AccountSettingType.INTERFACE_THEME)?_c('interface-picker',{attrs:{"items":item.items},model:{value:(_vm.localValue.theme),callback:function ($$v) {_vm.$set(_vm.localValue, "theme", $$v)},expression:"localValue.theme"}}):(
                      item.value === _vm.AccountSettingType.FILTER_LOCATION
                    )?_c('interface-picker',{attrs:{"items":item.items},model:{value:(_vm.localValue.filterLocation),callback:function ($$v) {_vm.$set(_vm.localValue, "filterLocation", $$v)},expression:"localValue.filterLocation"}}):(
                      item.value ===
                      _vm.AccountSettingType.ADDITIONAL_MENU_LOCATION
                    )?_c('interface-picker',{attrs:{"items":item.items},model:{value:(_vm.localValue.additionalMenuLocation),callback:function ($$v) {_vm.$set(_vm.localValue, "additionalMenuLocation", $$v)},expression:"localValue.additionalMenuLocation"}}):(
                      item.value === _vm.AccountSettingType.FILTERS_BEHAVIOR
                    )?_c('filters-behavior-picker',{attrs:{"title":_vm.$lang('accountSettings.defaultBehaviorTitle')},model:{value:(_vm.localValue.defaultFiltersBehavior),callback:function ($$v) {_vm.$set(_vm.localValue, "defaultFiltersBehavior", $$v)},expression:"localValue.defaultFiltersBehavior"}}):(
                      item.value ===
                      _vm.AccountSettingType.SPECIFIC_FILTERS_BEHAVIOR
                    )?_c('specific-filters-behavior',{on:{"scrollToTop":_vm.scrollToSpecificFiltersBehavior},model:{value:(_vm.localValue.specificFiltersBehavior),callback:function ($$v) {_vm.$set(_vm.localValue, "specificFiltersBehavior", $$v)},expression:"localValue.specificFiltersBehavior"}}):_vm._e()],1)],1)}):_vm._l((_vm.applicationItems),function(item,index){return _c('div',{key:item.value},[(index)?_c('v-divider',{staticClass:"mx-3"}):_vm._e(),_c('v-card-title',{staticClass:"text-h6 font-weight-medium pa-3"},[_vm._v(" "+_vm._s(item.title)+" ")]),_c('v-card-subtitle',{staticClass:"px-3 py-0"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-card-text',{staticClass:"pa-3"},[(
                      item.value === _vm.AccountSettingType.FAVORITE_APPLICATIONS
                    )?_c('favorite-applications-picker',{attrs:{"default-application":_vm.localValue.defaultApplication},on:{"removeDefaultApplication":_vm.removeDefaultApplication},model:{value:(_vm.localValue.favoriteApplications),callback:function ($$v) {_vm.$set(_vm.localValue, "favoriteApplications", $$v)},expression:"localValue.favoriteApplications"}}):(
                      item.value === _vm.AccountSettingType.DEFAULT_APPLICATION
                    )?_c('default-application-picker',{attrs:{"favorite-applications":_vm.localValue.favoriteApplications},model:{value:(_vm.localValue.defaultApplication),callback:function ($$v) {_vm.$set(_vm.localValue, "defaultApplication", $$v)},expression:"localValue.defaultApplication"}}):_vm._e()],1)],1)})],2)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }