





















































import { Component, Prop, Ref, Vue } from "vue-property-decorator";
import { cloneDeep } from "lodash";

import { Application } from "@/shared/models";
import { VueAutocomplete } from "@/shared/types/ExtendedVueType";

@Component
export default class FavoriteApplicationsPicker extends Vue {
  @Prop({ default: () => [] }) value!: Array<string>;
  @Prop({ default: "" }) defaultApplication!: string;

  @Ref("autocomplete") readonly autocomplete!: VueAutocomplete;

  isShowPicker = false;
  favoriteApp: string | null = null;

  get applications(): Array<Application> {
    return this.$store.state.application.apps.reduce(
      (result: Array<Application>, app: Application) => {
        if (this.value.some((item) => item === app.id)) {
          return result;
        }

        return [
          ...result,
          {
            text: app.name || app.id,
            value: app.id,
          },
        ] as Array<Record<string, string>>;
      },
      [] as Array<Record<string, string>>
    );
  }

  getApplicationNameById(appId: string): string {
    return this.$store.getters.applicationsById.get(appId)?.name || "";
  }

  setIsShowPicker(value: boolean) {
    this.isShowPicker = value;
    this.autocomplete.isFocused = value;
    this.autocomplete.isMenuActive = value;
  }

  addFavoriteApp(value: string) {
    const clonedValue = cloneDeep(this.value);

    clonedValue.push(value);
    this.$emit("input", clonedValue);
    this.setIsShowPicker(false);
  }

  removeFavoriteAppById(appId: string) {
    let clonedValue = cloneDeep(this.value);

    clonedValue = clonedValue.filter((item) => item !== appId);

    if (appId === this.defaultApplication) {
      this.$emit("removeDefaultApplication", appId);
    }

    this.favoriteApp = null;
    this.$emit("input", clonedValue);
  }
}
