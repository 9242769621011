




































































































































































































import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { NavigationGuardNext, Route } from "vue-router";
import { cloneDeep } from "lodash";

import InterfacePicker from "@/account-settings/components/InterfacePicker.vue";
import FiltersBehaviorPicker from "@/account-settings/components/FiltersBehaviorPicker.vue";
import SpecificFiltersBehavior from "@/account-settings/components/SpecificFiltersBehavior.vue";
import DefaultApplicationPicker from "@/account-settings/components/DefaultApplicationPicker.vue";
import FavoriteApplicationsPicker from "@/account-settings/components/FavoriteApplicationsPicker.vue";
import {
  AccountSettingType,
  AdditionalMenuLocationType,
  FilterLocationType,
  ThemeType,
} from "@/account-settings/models/AccountSettingType";
import AccountSettingsModel from "@/account-settings/models/AccountSettingsModel";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";

@Component({
  components: {
    InterfacePicker,
    FiltersBehaviorPicker,
    SpecificFiltersBehavior,
    DefaultApplicationPicker,
    FavoriteApplicationsPicker,
  },
})
export default class AccountSettingsView extends mixins(UnsavedChangesMixin) {
  readonly AccountSettingType = AccountSettingType;
  readonly tabItems = [
    {
      text: this.$lang("accountSettings.tabs.interface"),
      icon: "mdi-compare",
    },
    {
      text: this.$lang("accountSettings.tabs.applications"),
      icon: "mdi-application-cog-outline",
    },
  ];
  readonly interfaceItems = [
    {
      title: this.$lang("accountSettings.interfaceThemeTitle"),
      text: this.$lang("accountSettings.interfaceThemeText"),
      value: AccountSettingType.INTERFACE_THEME,
      items: [
        {
          text: this.$lang("accountSettings.light"),
          value: ThemeType.LIGHT,
          imgSrc: require("@/account-settings/assets/light.svg"),
        },
        {
          text: this.$lang("accountSettings.dark"),
          value: ThemeType.DARK,
          imgSrc: require("@/account-settings/assets/dark.svg"),
        },
      ],
    },
    {
      title: this.$lang("accountSettings.filterLocationTitle"),
      text: this.$lang("accountSettings.filterLocationText"),
      value: AccountSettingType.FILTER_LOCATION,
      items: [
        {
          text: this.$lang("accountSettings.horizontal"),
          value: FilterLocationType.HORIZONTAL,
          imgSrc: require("@/account-settings/assets/horizontal.svg"),
        },
        {
          text: this.$lang("accountSettings.vertical"),
          value: FilterLocationType.VERTICAL,
          imgSrc: require("@/account-settings/assets/vertical.svg"),
        },
      ],
    },
    {
      title: this.$lang("accountSettings.locationForAdditionalMenuTitle"),
      text: this.$lang("accountSettings.locationForAdditionalMenuText"),
      value: AccountSettingType.ADDITIONAL_MENU_LOCATION,
      items: [
        {
          text: this.$lang("accountSettings.collapsed"),
          value: AdditionalMenuLocationType.COLLAPSED,
          imgSrc: require("@/account-settings/assets/collapsed.svg"),
        },
        {
          text: this.$lang("accountSettings.expanded"),
          value: AdditionalMenuLocationType.EXPANDED,
          imgSrc: require("@/account-settings/assets/expanded.svg"),
        },
      ],
    },
    // TODO: temp comment. Component settings will be in feature
    {
      title: this.$lang("accountSettings.filtersBehaviorTitle"),
      text: this.$lang("accountSettings.filtersBehaviorText"),
      value: AccountSettingType.FILTERS_BEHAVIOR,
      items: [],
    },
    {
      title: this.$lang("accountSettings.specificFiltersBehaviorTitle"),
      text: "",
      value: AccountSettingType.SPECIFIC_FILTERS_BEHAVIOR,
      items: [],
    },
  ];
  readonly applicationItems = [
    {
      title: this.$lang("accountSettings.favoriteApplicationsTitle"),
      text: this.$lang("accountSettings.favoriteApplicationsText"),
      value: AccountSettingType.FAVORITE_APPLICATIONS,
    },
    {
      title: this.$lang("accountSettings.defaultApplicationTitle"),
      text: this.$lang("accountSettings.defaultApplicationText"),
      value: AccountSettingType.DEFAULT_APPLICATION,
    },
  ];
  selectedListItem = 0;
  valid = true;
  localValue = new AccountSettingsModel();
  watchedAccountSettings = false;

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return cloneDeep(this.localValue);
  }

  get isSavingInProgress(): boolean {
    return this.$store.state.accountSettingsStore.isSavingInProgress;
  }

  get accountSettings(): AccountSettingsModel {
    return this.$store.state.accountSettingsStore.accountSettings;
  }

  @Watch("accountSettings", { immediate: true, deep: true })
  private watchAccountSettings(value: AccountSettingsModel) {
    this.isSavedForm = false;
    this.localValue = AccountSettingsModel.of(value);

    if (!this.watchedAccountSettings) {
      this.watchedAccountSettings = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("accountSettings.title")
    );
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  removeDefaultApplication() {
    this.localValue.defaultApplication = null;
  }

  scrollToSpecificFiltersBehavior() {
    document
      ?.getElementById("specificFiltersBehavior")
      ?.scrollIntoView({ behavior: "smooth" });
  }

  resetToPrevState() {
    this.localValue = AccountSettingsModel.of(this.accountSettings);
  }

  async handleSave() {
    await this.$store.dispatch("updateAccountSettings", {
      payload: this.localValue,
      isNeedNotification: true,
    });

    this.isSavedForm = true;
    this.initialData = "";
    this.$vuetify.theme.dark = this.localValue.theme === ThemeType.DARK;
  }
}
