
























































































































import { Component, Prop, Ref, Vue } from "vue-property-decorator";

import { FilterBehaviorOption } from "@/account-settings/models/AccountSettingType";
import { VueAutocomplete } from "@/shared/types/ExtendedVueType";
import { FilterId } from "@/shared/models";

@Component
export default class FiltersBehaviorPicker extends Vue {
  @Prop() value!: Array<FilterBehaviorOption>;
  @Prop() title!: string;
  @Prop({ default: false }) isSpecificFilter!: boolean;
  @Prop() specificFilterValue!: FilterId;

  @Ref("autocomplete") readonly autocomplete!: VueAutocomplete;

  readonly options = Object.entries(FilterBehaviorOption).map(
    ([, value]: Array<string>) => ({
      label: this.$lang(
        `accountSettings.filtersBehaviorOptions.${value.toLowerCase()}`
      ),
      value,
    })
  );
  readonly autocompleteOptions = [
    "value 1",
    "value 2",
    "value 3",
    "value 4",
    "value 5",
    "value 6",
  ];
  values: Array<string> = [];
  holderElement: HTMLElement | null = null;
  isAttached = true;
  eager = false;
  top = true;
  search = "";

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get clearable(): boolean {
    return this.selectedOptions.includes(FilterBehaviorOption.CLEARABLE);
  }

  get clearSearchValueAfterSelect(): boolean {
    return this.selectedOptions.includes(
      FilterBehaviorOption.CLEAR_SEARCH_VALUE_AFTER_SELECT
    );
  }

  get closeAfterSelect(): boolean {
    return this.selectedOptions.includes(
      FilterBehaviorOption.CLOSE_AFTER_SELECT
    );
  }

  get sortedItems(): Array<string> {
    return [...this.autocompleteOptions].sort(
      (itemA: string, itemB: string) => {
        if (
          !this.values ||
          (this.values.includes(itemA) && this.values.includes(itemB)) ||
          (!this.values.includes(itemA) && !this.values.includes(itemB))
        ) {
          return 0;
        }

        return this.values.includes(itemA) ? -1 : 1;
      }
    );
  }

  get selectedOptions(): Array<string> {
    return this.value;
  }

  set selectedOptions(value: Array<string>) {
    this.$emit("input", value);
  }

  mounted() {
    this.holderElement = this.autocomplete.$el as HTMLElement;
    this.eager = true;
  }

  isSelected(item: string): boolean {
    return this.values.includes(item);
  }

  toggleItem(item: string) {
    if (this.values.includes(item)) {
      this.values = this.values.filter((value) => value !== item);
    } else {
      this.values.push(item);
    }

    this.clearSearch();
    this.autocomplete.focus();
  }

  handleClickItem(item: string) {
    this.toggleItem(item);

    if (this.closeAfterSelect) {
      this.autocomplete.blur();
    }
  }

  clearSearch() {
    if (this.clearSearchValueAfterSelect) {
      this.search = "";
    }
  }

  onFocus(event: Event) {
    if (!this.isAttached) {
      return;
    }

    const targetElement = event.target as HTMLElement;

    this.top =
      targetElement.getBoundingClientRect().top +
        targetElement.offsetHeight / 2 >
      window.innerHeight / 2;
  }

  handleChangeOptions(value: Array<FilterBehaviorOption>) {
    this.$emit("input", value);
  }
}
