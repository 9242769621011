














































import { VueAutocomplete } from "@/shared/types/ExtendedVueType";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import FiltersBehaviorPicker from "@/account-settings/components/FiltersBehaviorPicker.vue";
import SpecificFiltersBehaviorModel from "@/account-settings/models/SpecificFiltersBehaviorModel";
import { FilterId, FilterPreviewId } from "@/shared/models";

@Component({
  components: {
    FiltersBehaviorPicker,
  },
})
export default class SpecificFiltersBehavior extends Vue {
  @Prop() value!: Array<any>;

  @Ref("autocomplete") readonly autocomplete!: VueAutocomplete;

  isShowPicker = false;
  specificFilterBehavior: string | null = null;
  filters: Array<SpecificFiltersBehaviorModel> = [];

  get filterItems(): Array<Record<string, string>> {
    return [
      FilterId.SEGMENT,
      FilterId.COUNTRY,
      FilterId.EVENTS_COUNTRY,
      FilterId.SOURCE,
      FilterId.SUB_SOURCE,
      FilterId.CAMPAIGN,
      FilterId.AD_SET,
      FilterId.CREATIVE,
      FilterId.PUBLISHER,
      FilterId.ATTRIBUTION_STATUS,
      FilterId.DEVICE_TYPE,
      FilterId.DEVICE_MODEL,
      FilterId.INSTALL_APP_VERSION,
      FilterId.EVENTS_APP_VERSION,
      FilterId.EVENTS_OS_VERSION,
      FilterId.WATERFALLS_ID,
      FilterId.AD_NETWORK_NAME,
      FilterId.LANGUAGE,
      FilterId.OS_VERSION,
      FilterId.APP_VERSION,
      FilterPreviewId.EVENT_COST,
    ]
      .filter((item: string) =>
        this.filters.every(({ value }: any) => value !== item)
      )
      .map((value) => ({
        text: this.$lang(`shared.filters.${value.toLowerCase()}`),
        value,
      }));
  }

  @Watch("value", { immediate: true, deep: true })
  watchValue() {
    this.filters = SpecificFiltersBehaviorModel.ofArray(this.value);
  }

  setIsShowPicker(value: boolean) {
    this.isShowPicker = value;
    this.autocomplete.isFocused = value;
    this.autocomplete.isMenuActive = value;
  }

  async addSpecificFilterBehavior(value: FilterId) {
    this.filters.unshift(new SpecificFiltersBehaviorModel(value));
    this.setIsShowPicker(false);
    this.$emit("input", this.filters);

    await this.$nextTick();
    this.$emit("scrollToTop");
  }

  deleteFilter(deletedFilterValue: string) {
    this.filters = this.filters.filter(
      ({ value }) => value !== deletedFilterValue
    );
    this.specificFilterBehavior = null;
    this.$emit("input", this.filters);
  }

  handleChangeOptions() {
    this.$emit("input", this.filters);
  }
}
