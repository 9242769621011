



































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class InterfacePicker extends Vue {
  @Prop() value!: string;
  @Prop() items!: Array<Record<string, any>>;

  get selected(): string {
    return this.value;
  }

  set selected(value: string) {
    this.$emit("input", value);
  }
}
